var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row"},_vm._l((_vm.emsList),function(ems){return _c('div',{key:ems.id,staticClass:"overlay mr-4 mb-8 w-200px ml-4",on:{"click":function($event){return _vm.changeSite(ems)}}},[_c('div',{staticClass:"cursor-pointer",style:([
        ems.NAME != _vm.getActiveSite
          ? {
              filter: 'grayscale(100%)',
              transform: 'scale(0.9)',
              transition: 'all 1.5s ease',
            }
          : { transition: 'all 1.5s ease', transform: 'scale(1.2)' },
      ])},[_c('span',{staticClass:"text-dark ems-text_name text-xl"},[_vm._v(_vm._s(ems.NAME))]),_c('img',{staticClass:"w-100 rounded",staticStyle:{"height":"130px"},attrs:{"src":_vm.getImage(ems.NAME),"alt":""}})])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }